
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;500;600;700;800&display=swap');

:root {
  --main: #fdfdfd;
  --main1: #fafafa;
  --main2: #ececec;
  --main3: #dadada;
  --blue: #325d96;
  --header: #002D74;
  --text: #262626;
  --text-one: #fff;
  --both-white:#fff;
  --both-black:#000;
  --step:#999999;
  --step1:#000;
  --newsletter:#f2f2f2;
  --news-title: #002D74;
  --tutorial-button:#f2f2f2;
  --connect-wallet:#ffffff;
  --note-color:#1a4283;
  --dropdown-color:#1a4283;
  --collection-item-color:#F2F2F2;

}

[data-theme=dark]{
  --main: #212121;
  --main1: #101010;
  --main2: #080808;
  --main3: #000000;
  --blue: #325d96;
  --header: #191919;
  --text: #fff;
  --text-one: #000;
  --both-white:#fff;
  --both-black:#000;
  --step:#3b3b3b;
  --step1:#fff;
  --newsletter:#000;
  --news-title: #3770cc;
  --tutorial-button:#191919;
  --connect-wallet:#1a4283;
  --note-color:#3671d3;
  --dropdown-color:#101010;
  --collection-item-color:#00000080;

}

body{
  background-color: var(--main);
}

a{
  text-decoration: none;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #000000 #0000003b;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #0000003b;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #00000083;
    border-radius: 10px;
    border: 0px;
  }

.App{
  margin: 0 auto;
}

.App-1280 {
  max-width: 1280px;
}

.navigation-container{
  width:100%;
  background-color: var(--header);
  display:flex;
  justify-content: center;
}

.navigation{
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 5em;
  align-items: center;
  color: var(--text);
  max-width: 1280px;
}

.right button{
  background-color: var(--connect-wallet);
  border: 0;
  padding: 10px;
  color: var(--text);
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  font-weight: 600;
  padding-right: 1em;
  padding-left: 1em;
  border-radius: 10em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right button:hover{
  transition: 1s;
  cursor: pointer;
}

.menu ul{
  display: flex;
  flex-direction: row;
  color: var(--text);

}

.menu ul li{
  list-style: none;
  text-align: center;
  margin-left: 25px;
  margin-right: 25px;
}

.menu ul li a{
  -webkit-mask-image: linear-gradient(45deg,#FFF 25%,rgba(0,0,0,.2) 50%,#FFF 75%);
  mask-image: linear-gradient(45deg,#FFF 25%,rgba(0,0,0,.2) 50%,#FFF 75%);
  -webkit-mask-size: 800%;
  mask-size: 800%;
  -webkit-mask-position: 0;
  mask-position: 0;
  font-weight: 800;
  font-size:1em;
  color: var(--both-white);
}

.menu ul li a:hover{
  color: var(--both-white);
  font-size: 1.05em;
  transition: mask-position 3s ease,-webkit-mask-position 3s ease, font-size 1s ease;
  -webkit-mask-position: 120%;
  mask-position: 120%;
  opacity: 1;
}

.logo{margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 90%;
  display: flex;
  align-items: center;
  margin-left: 0;
}

.logo a img{
height: 50%;
}

.logo a{
  font-size: 1.5em;
  margin-left: 0.5em;
  height: 100%;
  display: flex;
  align-items: center;
}

.mobile-menu{
  display: none;
}

.mobile-menu button{
  background-color: var(--main1);
  width: 100%;
  font-size: 1.2em;
  border: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.397);
}

.mobile-menu button a{
  color: var(--text);
}

.main-container{
  position: relative;
  max-width: 1280px;
  width: 100%;
  background-color: var(--main);
  flex-direction: column;
  justify-content: center;
}

.banner{
  margin-top: 1em;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 600px;
  background-repeat: no-repeat;
  background: url(./images/lines.svg);
  background-size: cover;
}

.projects-container{
  width: 100%;
  margin-top: 3em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-family: 'Montserrat', sans-serif;
  flex-wrap: wrap;
  padding-top: 1em;
  padding-bottom: 5em;
}

.project-item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align:justify;
  padding: 1em;
  width: 25%;
}

.statements-container{
  width: 100%;
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: 'Montserrat', sans-serif;
  flex-wrap: wrap;
  padding-top: 1em;
  padding-bottom: 5em;
}

.statement-item{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  background-color: var(--main);
}

.statement-image{width: 30%;}

.statement-desc{
  color: var(--text);
  width: 70%;
}

.statement-desc h1{
  color: var(--news-title);
}

#mission{
  background-color: var(--news-title);
  color: white;
  padding-left: 2em;
}

#mission h1{
  color: white;
}

#mission P{
  color: white;
}



#project-item2{
  color: var(--text);
  background-color: var(--tutorial-button);
}

.project-item a{
  color: white;
}
.project-item h1{
  margin-bottom: 0;
  color: var(--news-title);
}

.project-item p{
  min-height: 200px;
  color: var(--text);
}

.project-item button{
  width: 100%;
  border: 0;
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
  background-color: var(--news-title);
  color: white;
  font-family: 'Montserrat', sans-serif;
  margin-top: 1em;
  font-weight: 600;
  border-radius: 3em;
  cursor: pointer;
}

.note{
  color: var(--note-color);
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  border-bottom: 1px solid #1717179f;
  margin: 0;
}

.note h2{
  margin:0;
  padding:1em;
  font-weight: 500;
}

.banner-left{
  width: 100%;
  color: var(--text);
  font-family: 'Montserrat', sans-serif;
  margin-left: 2em;
}

.banner-title{
  font-family: 'Montserrat', sans-serif;
  font-size: 3.5em;
  font-weight: 800;
  color: var(--news-title);
}

.blink-caret{
  font-family: 'Montserrat', sans-serif;
  font-size: 4em;
  font-weight: 400;
  animation: blink-caret 1s step-start infinite;

}

@keyframes blink-caret {
  50% {
    opacity: 0;
  }
}

.banner-left p{ 
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  color: var(--text);
  font-weight: 400;
  max-width: 500px;
}

.banner-left b{ 
  color: #4a7dc0;
}

.button { 
  padding: 0.5em;
  padding-left: 2em;
  padding-right: 2em;
  background-color: var(--news-title);
  border: 0;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2em;
  font-weight: 800;
  border-radius: 10em;
  cursor: pointer;
  margin-top: 1em;
}

.button a {
  color: white;
}

.banner-right img{
  margin-right: 4em;
}



.information-box{
  margin-top: 2em;
  padding-top: 2em;
  padding-bottom: 2em;
  width: 100%;
  min-height: 10em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 1em;
}

.info-box{
  color: white;
  display: flex;
  height: 10em;
  align-items: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  padding-left: 2em;
  padding-right: 2em;
  border-radius: 0.5em;
  background: url(./images/crd1.svg);
  background-repeat: no-repeat;
  background-size:cover;
  background-color: transparent;
}

.info-box:hover{
  animation: cards 1s forwards;
}


.info-box h2{
  color: var(--text);
  font-size: 1.7em;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

.info-box h1{
  margin-top: -20px;
  color: #4a7dc0;
  font-size: 2.5em;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}

#trade-title{  
  padding-left: 1em;
  margin-top: 5em;
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-weight: 600;
  padding-bottom: 0.5em;
  font-size: 1.5em;
  margin-bottom: 0;
}


.last-trades{
  width: 100%;
  display:flex;
  flex-direction: row;
  align-items: center;
  max-width: 1280px;
  position: relative;
  overflow:scroll;
  overflow-y: hidden;
  margin-top: 0;
  background-color: var(--main);
}

.last-trade-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0;
  border-right: 1px solid black;
  color: var(--blue);

}

.last-trade-item{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2em;
  padding: 0.5em;
  margin-top: 0;
}


.last-trade-item a{
  color:white;
  font-size: 0.4em;
  margin: 0;
}

.last-trade-item img{
max-width: 100px;
}

.last-trade-left{
  width: 100%;
  position:absolute;
  display: flex;
  justify-content: space-between;
}

.last-trade-item h4{
  font-size: 0.3em;
  margin: 0;
  color: var(--text);
  padding-bottom: 0.5em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}


.last-trade-item h5{
  font-size: 0.4em;
  margin: 0;
  color: var(--text);
  padding-bottom: 0.5em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: center;
}


.trade-wrapper{

}

.trade-header{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.trades-message-text{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.trades-message-text button {

}

.trade-offer-desc li{
  text-align: left;
  list-style-type:decimal;
  color: var(--text);
  font-family: 'Montserrat', sans-serif;

}

.trades-message-text h2{
  font-family: 'Montserrat', sans-serif;
  color: var(--news-title);
}

.receive-trade-offer{
  background-color: var(--main3);
  width: 50%;
  text-align: center;
}


.sent-trade-offer{
  background-color: var(--main2);
  width: 50%;
  text-align: center;
}

.receive-trade-offer h2{
  color: var(--news-title);
  font-family: 'Montserrat', sans-serif;
  padding: 0.1em;

}

.sent-trade-offer h2{
  color: var(--news-title);
  font-family: 'Montserrat', sans-serif;
  padding: 0.1em;
}

.send-offer{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.send-offer-form{
  width: 90%;
}

.send-offer-form input{
  width: 58%;
  padding: 1em;
  border-top-left-radius: 5em;
  border-bottom-left-radius: 5em;
  border: 0;
  font-family: 'Montserrat', sans-serif;
}

.send-offer-form button{
  width: 30%;
  padding: 1em; 
  border: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  cursor: pointer;
  background-color: var(--news-title);
  color: var(--both-white);
}


.wallet-info{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2em;
  color: white;
  font-family: 'Montserrat', sans-serif;
  width: 50%;
}

.wallet-pic{
  width: 200px;
  height: 200px;
}
.wallet-pic img{
  max-width: 200px;
  border-radius: 10em;
  border: 2px solid rgba(0, 0, 0, 0.616);
  width: 200px;
  height: 200px;
}

.trade-url{
  color: var(--text);
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  align-items: center;
}

.trade-url h4{
  padding-left: 1em;
  margin-bottom: 10px;
}

.trade-url p{
  font-size: 0.8em;
  padding-left: 1em;
}

.trade-url button{
cursor: pointer;
}

.alert{
  font-family: 'Montserrat', sans-serif;
  color: white;
  position: fixed;
  width: 100%;
  background-color: #208b4d;
  height: 3em;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6em;
  padding: 1em;
  z-index: 9999;
}

.alert-error{
  background-color: #a12211;
}

.alert-info{
  background-color: var(--blue);
}

.trade-url input{
  width: 50%;
  padding: 1em;
  padding-left: 2em;
  border-radius: 2em;
  background-color: var(--main1);
  border: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: var(--text);
  font-family: 'Montserrat', sans-serif;
}

.trade-url button{
  padding: 1em;
  border: 0;
  margin-left: 0;
  background-color: var(--news-title);
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.trade-nav-menu {
  background-color: var(--main2);
  padding: 0.05em;
  border-top: 2px solid var(--blue);
}

.wallet-address{
  margin-top: 0.7em;
  padding: 0.5em;
  border: 2px solid #00000081;
  border-radius: 2em;
  padding-right: 1em;
  padding-left: 1em;
  font-size: 0.8em;
  color: var(--text);
}

.right-walletaddress{
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--both-white);
  cursor: pointer;
}

.trade-nav-menu ul{
  display: flex;
  justify-content: space-around;
  list-style-type:none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.trade-nav-menu li{
  padding: 1em;
  text-align: center;
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text);
}

.active-trade-menu{
  border-bottom: 2px solid #325d96;
}

.trade-page-container{
  font-family: 'Montserrat', sans-serif;
  color: var(--text);
  background-color: var(--main1);
}

.trade-offer-wrapper{
  width: 100%;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  position:relative;
}

.trade-offer-header{
  width: 100%;
  background-color: var(--main3);
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  padding-top: 1em;
  padding-bottom:1em ;
  justify-content: space-around;
  
}

.trade-offer-header h3{
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text);
}


.trade-offer-header h3 b{
 color: var(--blue);
 font-weight: 800;

}

.trade-offer-pagination{
  width: 100%;
  display: flex;
  flex-direction: row;
}

.trade-offer-pagination button{
  width: 100%;
  min-height: 30px;
  border: 0;
  cursor: pointer;
  color: white;
  font-family: 'Montserrat', sans-serif;
  background-color: var(--main2);
  border-bottom: 1px solid var(--news-title);
  border-top: 1px solid var(--news-title);
  color: var(--text);
  font-weight: 800;
}

.trade-offer-pagination button:disabled{
  background-color: var(--news-title);
  color: var(--both-white);
}

.offer-date{
  display: flex;
  font-family: 'Montserrat', sans-serif;
  background-color: #00000079;
  margin-left: 1em;
  margin-right: 2em;
  padding-left: 1em;
  padding-right: 1em;
}

.trade-status-active{
  border: 2px solid rgb(39, 167, 66) ;
  padding: 0.5em;
  padding-right: 2em;
  padding-left: 2em;
  font-weight: 600;
  color: var(--text);
}

.trade-status-deactive{
  position: absolute;
  right: 2em;
  background-color: rgb(255, 58, 58);
  padding: 0.5em;
  border-radius: 1em;
  padding-right: 2em;
  padding-left: 2em;
  font-weight: 600;
  color: var(--both-white);

}

.trade-offer-container{
  background-color: var(--main1);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.given-nfts-container{
  background-color: var(--main2);
  width: 50%;
  overflow:scroll;
  overflow-x: hidden;
  color: var(--text);
}


.given-nfts{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.given-nfts-title{
  width: 100%;
  text-align: center;
  background-color: var(--blue);
  color: var(--both-white);
}

.given-nfts-title h3{
  font-family: 'Montserrat', sans-serif;
  padding: 0.5em;
  margin: 0;
}

.trade-offer-buttons{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: var(--main3);
}

.terms label{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  padding: 0.2em;  
}

.terms b{
  color: #4a7dc0;
  font-weight: 800;
}

.trade-offer-buttons button{
  padding:1em;
  border: 0;
  width: 200px;
  margin-right: 1em;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

#accept-button{
  background-color: rgb(39, 167, 66);
  cursor: pointer;
}

#accept-button:hover{
  background-color: rgb(55, 184, 83);
}

#accept-button:disabled{
  background-color: rgb(67, 134, 82);
  cursor: not-allowed;
}

#decline-button{
  background-color: rgb(255, 58, 58);
  cursor: pointer;
}

#decline-button:hover{
  background-color: rgb(248, 73, 73);
}

.offered-nft-item{
  padding: 1em;
}

.offered-nft-image, .offered-nft-image img{
  max-width: 100px;
  max-height: 100px;
}

.offered-nft-image img{
  border: 1px solid #000;
}

.offered-nft-information{
  font-size: 0.8em;
  margin-top: 0.3em;
  text-align: center;
  font-weight: 600;
}

.received-nfts{
  background-color: #584c4c;
}

.reveived-offer-buttons{
  width: 100%;
  background-color: white;
}

.trade-loading{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #000000b0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  z-index: 9999;
}

.trade-loading a{
  background-color: var(--blue);
  padding: 0.8em;
  border: 0;
  color: white;
  border-radius: 3em;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

.trade-loading a:hover{
  background-color: #3c73bb;
}

.tutorial{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  border-radius: 1em;
  padding-top: 2em;
  padding-bottom: 2em;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  flex-wrap: wrap;
}

.tutorial-buttons{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 5em;
  flex-wrap: wrap;
}

.tutorial-button{
  font-weight: 500;
  padding: 0.5em;
  width: 20%;
  color: var(--text);
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3em;
  background-color: var(--tutorial-button);
  border: 0;
  cursor: pointer;
  margin-top: 1em;
  border-radius: 2em;
}

.activebutton{
  color: white;
  background-color: var(--news-title);
  box-shadow: #000 1px 1px 1px 1px inset
}



#tutorial-title{
    margin-top: 2em;
    text-align: center;
    text-transform: uppercase;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--news-title);
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 3em;
    border-style: solid;
    border-width: 3px;
    border-image: linear-gradient(to left, var(--main) 0%, rgba(50,93,150,1) 50%, var(--main) 100%) 0% 0 100% 0/3px 0 3px 0 stretch;
}


.bar-container{
  position: relative;
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bar-dot{
  width: 25px;
  height: 25px;
  background-color: #353535;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  border: 0;
  color: white;
}

#dot1{
  animation: beat 9s infinite;
}

#dot2{
  animation: beat1 9s infinite;
}

#dot3{
  animation: beat2 9s infinite;
}

#dot4{
  animation: beat3 9s infinite;
}

#step1{animation: step 9s infinite;}
#step2{animation: step1 9s infinite;}
#step3{animation: step2 9s infinite;}
#step4{animation: step3 9s infinite;}


.dot-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.ghost-line{
  width: 5px;
  background-color: #000000;
  height: 100%;
  position:absolute;
  z-index: 0;
  border-radius: 10px;
}

.bar-line{
  transition: all 5s;
  width: 5px;
  background-color: var(--news-title);
  position:absolute;
  z-index: 1;
  animation: slideDown 9s infinite linear;
  border-radius: 10px;
}

.tutorial-text{
  padding-left: 2em;
  padding-right: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30%;
}

.tutorial-step{
  color: var(--step);
}

.tutorial-video{
  font-family: 'Montserrat', sans-serif;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 2em;
}

.tutorial-video video{
  width: 100%;
}

.tutorial-text h2{
  font-weight: 600;
  margin-bottom: 0;
}

.tutorial-text p{
  margin-top: 0;
}

.footer{
  width: 100%;
  position: relative;
  margin-top: 2em;
}

.footer-menus{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: url(./images/lines.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  margin-bottom: 2em;
}

.footer-menu{
  color: var(--text);
  padding: 2em;
}

.footer-menu h4{
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1em;
  margin-bottom: 0;
}

.footer-menu li{
  list-style:none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 1em;
  padding-top: 0.1em;
}

.footer-menu li a{
  color: var(--text);
  cursor: pointer;
}

.social-box{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-weight: 400;
  min-height: 5em;
  border-top: 1px solid #4b4b4b5d;
  border-bottom: 1px solid #4b4b4b5d;
  border-radius: 4em;
}

.social-box a{
  display: flex;
  font-family: 'Montserrat', sans-serif;
  padding: 1em;
  border-radius: 2em;
  color: var(--text);
}

.social-box a:hover{
  cursor: pointer;
  transition: all 1s;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  padding: 1em;
  border-radius: 2em;
}

.social-box img{
  margin-right: 0.5em;
}

.footer-info{
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: var(--text);
  margin-top: 3em;
  padding: 2em;
  padding-top: 0;
}

.footer-info a{
  color: var(--news-title);
  cursor: pointer;
  font-weight: 600;
}

.displaynone{
  display: none;
}

.warning{
  background-color: #a12211;
  width: 100%;
  margin-top: 2em;
  color: white;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0.5em;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 2em;
}

.warning h2{
  margin: 0;
  margin-left: 0.5em;
}

.warning p{
  margin: 5px;
  margin-left: 0.8em;
}

.warning button{
  border: 0;
  background-color: #0000002f;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2em;
  color: white;
  padding-left: 2em;
  padding-right: 2em;
  cursor: pointer;
  border-radius: 0.5em;
  margin-right: 1em;
  font-weight: 800;
}

.trade-information{
  position: relative;
  width: 100%;
  color: var(--text);
  background-color: var(--main2);
  margin: 0;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  font-family: 'Montserrat', sans-serif;
  margin-top: 2em;
}

.trade-information h3{
  margin: 0;
  padding-top: 1em;
  font-weight: 200;
  font-size: 1.2em;
  text-align: center;
  font-weight: 600;
}

.trade-information p{
  margin: 0rem;
  padding-bottom: 1em;
  font-weight: 400;
  text-align: center;
  color: var(--text);
  padding-left: 1em;
  padding-right: 1em;
}

.trade-information b{
  color: #4a7dc0;
}


.trade-area{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: 'Montserrat', sans-serif;
}

.trade-line{
  width: 0.5em;
  background-color: #00000057;
}

.trade-container{
  width: 100%;
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
  justify-content: center;
}

.trade-steps{
  width: 100%;
  margin-bottom: 1em;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--main2);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y:center;
  flex-wrap: wrap;
  min-height: 10em;

}

.trade-steps button{
  border: 0;
  background-color: var(--blue);
  color: white;
  padding: 1em;
  border-radius: 5em;
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  padding-left: 4em;
  padding-right: 4em;
  cursor: pointer;
  margin-left: 1em;
  margin-right: 1em;
}

.trade-steps button:hover{
  background-color: #417ac5;
}

.trade-steps button:disabled{
  cursor: not-allowed;
  background-color: #2c4668;
}

.trade-steps h4{
  color:white;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 1.5em;
  margin-left: 5em;
}

.nft-trade-item{
  max-width: 180px;
  min-height: 230px;
  background-color: var(--main3);
  display: flex;
  flex-direction: column;
  margin: 0.5em;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
}


#nft-trade-item-img{
  width: 100%;
  border-radius: 0.2em;
  cursor: pointer;
}

.nft-information{
  position: relative;
  color:var(--text);
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
}

.nft-information-minted{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.2em;
  width: 20px;
  height: 20px;
}

.marketplace-details{
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #8a8a8aad;

}

.nft-information-minted img {
  display: flex;
  align-items: center;
  justify-content: center;

}

.nft-information-minted a {
  color: var(--both-white);

}

.nft-information-name
{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--main3);
  flex-direction: column;
  height: 100%;
  position: relative;
}

#create-select-button{
  display: flex;
  flex-direction: row;
  background-color: #a30101;
  align-items: center;
  padding: 0.5em;
  border: 0;
  font-family: 'Montserrat', sans-serif;
  color: var(--both-white);
  text-align: center;
  width: 100%;
  justify-content: center;
  font-weight: 800;
  font-size: 1em;
  cursor: pointer;
}


.nft-information-name span{
  padding-left: 0.3em;
  margin-bottom: 0;
  font-size: 1em;
  font-family: 'Montserrat', sans-serif;
  padding: 10px;
}

.nft-information span a{
  cursor: pointer;
  z-index: 99;
}

.nft-information span a:hover::after{
  content: "provided by minted.network";
  position: absolute;
  background-color: var(--main2);
  padding: 1em;
  font-family: 'Montserrat', sans-serif;
  z-index: 1;
  color: var(--text);
}


.selected-nft{
  border: 3px solid #4a7dc0;
}

.trade-steps-container{
  width: 50%;
  margin-top: 1em;
}

.trade-steps-text{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color:var(--text);
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 0.7em;
}

.trade-bar-container{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.trade-ghost-line{
  height: 5px;
  background-color: #000000;
  width: 100%;
  z-index: 0;
  border-radius: 10px;
  position:absolute;

}

.trade-bar-line{
  transition: all 5s;
  height: 5px;
  background-color: var(--blue);
  position:absolute;
  z-index: 1;
  border-radius: 10px;
}

.trade-step-1{
  animation: tradestep1 1s linear forwards;
}
.trade-step-2{
  animation: tradestep2 1s linear forwards;
}
.trade-step-3{
  animation: tradestep3 1s linear forwards;
}
.trade-step-4{
  animation: tradestep4 1s linear forwards;
}

.trade-dot-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.trade-bar-dot{
  width: 25px;
  height: 25px;
  background-color: #353535;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  border: 0;
  color: white;
}

.no-tradeable{
  color: var(--text);
}

/* NEWS STYLES */
.news-collections{
  display: flex;
  flex-direction: row;
  overflow:scroll;
  overflow-y: hidden;
  height: 60px;
  padding-bottom: 0.5em;
  width: 100%;
  margin-top: 0.5em;
}

.news-collections input{
  padding: 1.2em;
  border: 1px solid black;
  background-color: var(--header);
  border-radius: 5em;
  color: var(--text-one);
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  outline: 0;
}

.news-collections input::placeholder{
  color: rgba(255, 255, 255, 0.644);
}

.news-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--newsletter);
  margin-left: 0.5em;
  margin-right: 0.5em;
  min-width: 250px;
  justify-content: space-evenly;
  border: 0;
  border-radius: 4em;
  cursor: pointer;
  border: 1px solid black;
}


.news-item h2{
  color: var(--text);
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 1.2em;
}

.news-item img{
  height: 80%;
}

.news-container{
  width: 100%;
}


.news-container h1{
  text-transform: uppercase;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text);
  font-family: 'Montserrat', sans-serif;
  font-size: 3em;
  border-style: solid;
  border-width: 3px;
  border-image: linear-gradient(to left, var(--main) 0%, rgba(50,93,150,1) 50%, var(--main) 100%) 0% 0 100% 0/3px 0 3px 0 stretch;
}

.news-container b{
  color: var(--news-title);
}

.news-table-element{
  width: 100%;
  background-color: var(--newsletter);
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2em;
  user-select: none;
}


.news-table h2{
  color: var(--text);
  font-family: 'Montserrat', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 2em;
  font-weight: 400;
}

.news-author{
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  width: 15%;
  color: var(--text);
}

.news-author h2{
  font-size: 1em;
  text-align: left;

}

.news-details{
  background-color: var(--newsletter);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
}

.news-details .news-content{
  color: var(--text);  
  font-family: 'Montserrat', sans-serif;
  word-wrap: break-word;
  max-width: 50%;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.news-details .news-content img{
  max-width: 1em;
}

.news-image-gallery{
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.news-image-gallery img{
  cursor: zoom-in; 
  max-width: 400px;
}

.news-image-gallery button{
  border: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #242424;
  color: white;
  cursor: pointer;
  padding: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

.news-image-control-panel{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}


.news-image-gallery h3{
  color: var(--text);
}


.news-table-element img{
  max-height: 80%;
}

.news-table-element h3{
  color: var(--text);
  font-family: 'Montserrat', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1em;
}

.news-table-element button{
  color: white;
  font-family: 'Montserrat', sans-serif;
  border: 0;
  background-color: var(--news-title);
  padding: 1em;
  border-radius: 10em;
  font-weight: 500;
  cursor: pointer;
}

.pagination{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pagination button{
  padding: 1em;
  background-color: var(--blue);
  border: 0;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-bottom: 5em;
  margin-top: 2em;
}

.pagination button:disabled{
  padding: 1em;
  background-color: #0355995b;
  cursor:not-allowed;
}

.newsletter-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  width: 100%;
}

.collections-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.collection-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--collection-item-color);
  color: white;
  margin-left: 1.5em;
  margin-right: 1.5em;
  margin-top: 2em;
  padding-top: 2em;
  padding-bottom: 2em;
  border-top: 1px solid #3a6eb1;
  font-family: 'Montserrat', sans-serif;
  width: 20%;
}

.newsletter-container h1{
  white-space:pre-wrap;
  text-align: center;
  overflow: hidden;
  color: var(--text);
  font-family: 'Montserrat', sans-serif;
  font-size: 2em;
  border-style: solid;
  border-width: 3px;
  border-image: linear-gradient(to left, var(--main) 0%, rgba(50,93,150,1) 50%, var(--main) 100%) 0% 0 100% 0/3px 0 3px 0 stretch;
}

.collection-item img{
  max-width: 200px; 
  max-height: 200px;
  border-bottom: 1px solid var(--main2);
  padding-bottom: 1em;
}

.collection-item h3{
  margin-top: 0; 
  font-weight: 600;
  color: var(--text);
}

.collection-item button{
  font-family: 'Montserrat', sans-serif;
  padding: 1em;
  padding-left: 2.5em;
  padding-right: 2.5em;
  border: 0;
  background-color: var(--news-title);
  color: white;
  cursor: pointer;
  margin-top: 2em;
  text-align: center;
  width: 80%;
  font-weight: 600;
}

.newsletter-container input{
  width: 50%;
  padding: 1.2em;
  border: 0;
  background-color: var(--header);
  border-radius: 5em;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  font-weight: 500;
}

.newsletter-container input::placeholder{
  color: white;
}


.newsletter-container input:focus{
  outline: none;
  border-color: #4a7dc0;
  box-shadow: 0 0 0 2px #4a7dc0;
}

#loading-text{
  margin-top: 2em;
  padding: 1em;
  background-color: var(--blue);
  font-family: 'Montserrat', sans-serif;
  color: white;
}

.modal{
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #000000d5;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;
}

.modal-container{
  position: relative;
  display: flex;
  flex-direction: column;
  height: 85%;
  background-color:#242424;
  font-family: 'Montserrat', sans-serif;
}

.modal-container img{
  height: 100%;
  width: 100%;
}

.modal-top{
  width: 100%;
  display: flex;
  flex-direction: row;
  max-height: 50px;
  background-color: #000000;
  color: white;
  justify-content: space-between;
  align-items: center;
}

.modal-top h2{
  margin-left: 2em;
  font-family: 'Montserrat', sans-serif;
  color: var(--text);
}

.modal-top button{
  margin-right: 2em;
  border: 1px solid var(--blue);
  background-color: transparent;
  color: var(--text);
  padding-right: 1em;
  padding-left: 1em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  cursor: pointer;
}

.modal-content{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}



.soon, .not-found{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
}

.not-found a{
  color: var(--news-title);
}


.soon h1, .not-found h1{
  white-space:pre-wrap;
  text-align: center;
  overflow: hidden;
  color: var(--blue);
  font-family: 'Montserrat', sans-serif;
  font-size: 4em;
  margin-top: 0;
}

.not-found h1{
  margin-bottom: 0;
}

.soon h2{
  white-space:pre-wrap;
  text-align: center;
  overflow: hidden;
  color: var(--text);
  font-family: 'Montserrat', sans-serif;
  margin-top: 0;
}

.soon p{
  text-align: center;
  color: var(--text);
  max-width: 400px;
}

.soon h4, .not-found h4{
  color: var(--text);
  margin-top: 0;
}

.dd-wrapper{
  position: relative;
  width: 100%;
  }

.dd-header{
  background-color: var(--dropdown-color);
  display: flex;
  padding: 0.7em;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 0;
  color: white;
}

.dd-header-title{
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: white;
}

.dd-list{
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  z-index: 999;
  align-items: center;

}

.dd-list-item{
  background-color: var(--dropdown-color);
  padding: 0.8em;
  border: 0;
  font-family: 'Montserrat', sans-serif;
  color: white;
  display: flex;
  text-align: left;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.dd-list-item:hover{
  background-color: #303030;
  cursor: pointer;
}

.slider-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
}

.slider-container {
  margin-top: 1em;
  position: relative;
  width: 90%;
  overflow: hidden;
  border-radius: 1em;
  min-height: 400px;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide.active {
  opacity: 1;
}

.slide-number {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  z-index: 5;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9em;
}

.dots {
  opacity: 0.5;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 5;
}

.dots:hover{
  opacity: 1;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: white;
  margin-right: 10px;
  border: none;
  outline: none;
  cursor: pointer;
}

.dot.active {
  background-color: black;
}

.prev-button,
.next-button {
  z-index: 5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color:transparent;
  color: rgb(255, 255, 255);
  background-color: #00000071;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
}

.prev-button:hover,
.next-button:hover {
  background-color: black;
  color: white;
}

.prev-button {
  left: 0px;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;

}

.next-button {
  right: 0px;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
}

.learn-more-button {
  z-index: 99;
  position: absolute;
  bottom: 10%;
  right: 2%;
  padding: 15px 20px;
  background-color: var(--blue);
  color: #333;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.learn-more-button:hover {
  background-color: #4a7dc0;
  color: var(--text);
}

.launchpad-container{
  margin-top: 4em;
}

.launchpad-container img{
  width: 100%;
  border-radius: 1em;
}

.live-collections-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
}

.live-collections{
  width: 90%;
}

.launchpad-collection-item{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.live-collections h1{
  color: white;
  font-family: 'Montserrat', sans-serif;
}

.launchpad-collection-image img{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  max-width: 300px;
}

.launchpad-collection-details{
  padding: 1.2em;
  width: 250px;
  background-color: var(--main2);
  border-radius: 1em;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: var(--text);
  padding-left: 2em;
  font-family: 'Montserrat', sans-serif;
}

.launchpad-info{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.9em;
}

.launchpad-collection-details h2{
  margin-top: 0;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.launchpad-collection-details button{
  cursor: pointer;
  border: 0;
  padding: 1em;
  width: 100%;
  background-color: var(--blue);
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  border-radius: 2em;
  margin-top: 2em;
}

.launchpad-container a, .slider-wrapper a{
  color: white;
}

.affiliates-container{
  margin-top: 2em;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
}

.affiliates-title{
  padding-bottom: 2em;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: white;
}

.affiliates-title h1{
  color: #4a7dc0;
}

.affiliates-title p{
  font-weight: 300;
  padding: 0.5em;
}

.affiliates-link{
  width: 100%;
  text-align: center;
  border: 1px solid black;
  padding-bottom: 2em;
}

.affiliates-link p{
  color: white;
  padding: 0.5em;
}

.affiliates-link label{
  font-size: 1.2em;
  color: white;
  font-weight: 600;
}

.affiliates-link button{
  padding: 1.2em;
  border: 0;
  background-color:var(--blue);;
  color: var(--text);
  padding-right: 2em;
  padding-left: 2em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600; 
  border-radius: 2em;
  margin-left: -5em;
}

.affiliates-link input{
  border: 0;
  padding: 1.2em;
  width: 50%;
  color: var(--text);
  font-family: 'Montserrat', sans-serif;
  border-radius: 2em;
  background-color: #0000007a;
}

.affiliates-link input:focus{outline: 0;}

.affiliates-information{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4em;
  flex-wrap: wrap;
}

.affiliates-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid var(--blue);;
  padding-top: 2em;
  background-color: #00000036;
  padding: 1em;
  width: 40%;
  color: white;
  text-align: center;
  margin-bottom: 2em;
  padding-bottom: 2em;
}

.affiliates-item h3{
  font-size: 1.3em;
  font-family: 'Montserrat', sans-serif;

}

.affiliates-item h1{
  margin-top: 0;
  font-size: 1.7em;
  font-family: 'Montserrat', sans-serif;
  background-color: #00000057;
  padding: 0.3em;
  padding-left: 1em;
  padding-right: 1em;
}

.affiliates-item p{
  font-size: 1em;
  font-family: 'Montserrat', sans-serif;
}

.affiliates-item button{
  width: 50%;
  background-color: var(--blue);;
  border: 0;
  padding: 1em;
  color: white;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  border-radius: 2em;
}

.affiliates-help{
  margin-top: 5em;
  font-family: 'Montserrat', sans-serif;
  color: var(--blue);
}

.affiliates-steps{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;
  flex-wrap: wrap;
}

.affiliates-step h3{
  font-size: 1.5em;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  color: var(--blue);
}

.affiliates-step h1{
  background-color: var(--blue);
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 10em;
}

.affiliates-step{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  background-color: #00000036;
  height: 100%;
  min-height:250px;
  padding: 1em;
  border-top: 1px solid var(--blue);;
  color: white;
}

.affiliates-step p{
  font-size: 1em;
  text-align: center;
}

#affiliate-title{
  font-size: 1.6em;
  padding-left: 0.5em;
}

.no-login-wrapper{
  width: 100%;
  min-height: 20em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-login-container{
  color: var(--text);
  padding: 1em;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.no-login-container button{
  background-color: var(--blue);
  border: 0;
  padding: 10px;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  font-weight: 600;
  padding-right: 1em;
  padding-left: 1em;
  border-radius: 10em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-login-container button:hover{
  background-color: #3a6eb1;
  transition: 1s;
  cursor: pointer;
}

.collection-tags{
  display: flex;
  flex-direction: row;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  flex-wrap: wrap;
}

.collection-tags label{
  cursor: pointer;
  margin-left: 1em;
  padding: 0.5em;
  background-color: var(--main2);
  display: flex;
  flex-direction: row;
  margin-top: 1em;
  color: var(--text);
}

.collection-tags label input{display: none;}

.selected-tag{
  border: 2px solid var(--news-title);
}

.bot-container{
  font-family: 'Montserrat', sans-serif;
}

.bot-container h1{
  color: var(--text);
  font-size: 3em;
  word-wrap:break-word;
}

.bot-container h2{
  font-size: 2em;
  margin-bottom: 0;
  word-wrap:break-word;
}


.bot-container a{
  color: var(--both-white);
}

.bot-header{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  text-align: center;
}

.bot-header a{
  background-color: var(--news-title);
  padding: 1em;
  color: var(--both-white);
  user-select: none;
  cursor: pointer;
  font-weight: 600;
}

.bot-steps{
  margin-top: 4em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bot-step{
  width: 45%;
  color: var(--both-white);
  background-color: var(--news-title);
  padding: 1em;
}

.bot-step p{
  font-weight: 400;
  word-wrap: break-word;
}

.bot-step:first-child{
  color: var(--text);
  background-color: var(--main1);
}

#bot-msg{
  margin: 0;
  margin-top: 0.5em;
  font-size: 0.8em;
  color: var(--text);
  text-align: center;
}

#bot-msg a{
  color: var(--news-title);
  font-weight: 600;
}

#redirect-text{
  text-align: center;
  color: var(--text);
  font-family: 'Montserrat', sans-serif;

}

.privacy, .privacy a{
  color: var(--text);
}

.tictactoe{
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: var(--text);
}

#restartbutton{
  padding: 1em;
  border: 0;
  background-color: var(--news-title);
  color: var(--both-white);
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.board {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  margin-top: 20px;
  background-color: var(--main2);
  font-family: 'Montserrat', sans-serif;
}

.square-container {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main3);
}

.square {
  width: 100px;
  height: 100px;
  font-size: 48px;
  border: none;
  background-color: var(--main);
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  color: var(--text);
}


.settings-wrapper{
  position: relative;
  width: 65px;
}

.settings-header{
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  background-color: transparent;
  border: 0;
  color: var(--both-white);
  height: 50px;
  background-color: #00000044;

}

.settings-header-title{

}

.settings-container{
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #00000044;
  padding-bottom: 1em;
}



@keyframes step{
  0%, 10% { color:var(--step);}
  11%, 32% {color: var(--step1);}
  33%, 34% { color:var(--step);}
}

@keyframes step1{
  0%, 35% { color:var(--step);}
  36%, 57% {color: var(--step1);}
  58%, 59% { color:var(--step);}
}

@keyframes step2{
  0%, 60% { color:var(--step);}
  61%, 82% {color: var(--step1);}
  83%, 84% { color:var(--step);}
}

@keyframes step3{
  0%, 85% { color:var(--step);}
  86%, 100% {color: var(--step1);}
}

@keyframes beat{
  0%, 10% { transform: scale(1); background-color:#353535; }
  11%, 32% { transform: scale(1.4); background-color: var(--news-title);}
  33%, 34% { transform: scale(1); background-color:#353535; }
}

@keyframes beat1{
  0%, 35% { transform: scale(1); background-color:#353535; }
  36%, 57% { transform: scale(1.4); background-color: var(--news-title); }
  58%, 59% { transform: scale(1); background-color:#353535; }
}

@keyframes beat2{
  0%, 60% { transform: scale(1); background-color:#353535; }
  61%, 82% { transform: scale(1.4); background-color: var(--news-title);}
  83%, 84% { transform: scale(1); background-color:#353535;  }
}

@keyframes beat3{
  0%, 85% { transform: scale(1); background-color:#353535; }
  86%, 100% { transform: scale(1.4); background-color: var(--news-title);}
}

@keyframes slideDown{
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}

@keyframes tradestep1{
  from {
    width: 0%;
  }
  to {
    width: 16%;
  }
}

@keyframes tradestep2{
  from {
    width: 16%;
  }
  to {
    width: 50%;
  }
}

@keyframes tradestep3{
  from {
    width: 50%;
  }
  to {
    width: 85%;
  }
}

@keyframes tradestep4{
  from {
    width: 85%;
  }
  to {
    width: 100%;
  }
}

@keyframes shake {
  0% { transform: skewX(-15deg); }
  5% { transform: skewX(15deg); }
  10% { transform: skewX(-15deg); }
  15% { transform: skewX(15deg); }
  20% { transform: skewX(0deg); scale: 105%; }
  100% { transform: skewX(0deg); }  
}

@keyframes cards {
  0% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1.2, 1.2);
  }
}

.dc-spoiler {
  background-color: #444;
  color: #444;
  padding: 0.2em;
}
.dc-spoiler:hover {
  background-color: none;
  color: #fff;
}
.dc-code {
  margin: 0;
  background-color: #2B2D31;
  color: #fff;
  display: inline-block;
}
.dc-user, .dc-channel {
  background-color: #3D4270;
  color: #fff;
  padding: 0 2px;
  border-radius: 2px;
}
.dc-user:hover, .dc-channel:hover {
  background-color: #5865F2;
}
.dc-multi-code {
  background-color: #2B2D31;
  border: 1px solid #1E1F22;
  border-radius: 5px;
  padding: 0.3em;
  white-space: pre;
  display: block;
}
.dc-quote {
  margin: 0;
  border-left: 3px solid #4E5058;
  padding-left: 6px;
}
.dc-link {
  color: #00A8FC;
}
.dc-timestamp {
  color: var(--both-white);
  background-color: var(--news-title);
}

.theme-button{
  font-size: 2em;
  background: none;
  border: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
}


@media only screen and (max-width: 1000px) {
  .menu{display: none;}
  .banner-right{display: none;}
  .info-box{margin-top: 2em;}
  .news-details img{max-width: 100%;}
  .logo c{display: none;}
  .modal-container{
    width: 100%;
    height: auto;
  }
  .trade-steps-container{
    width: 100%;
    margin-bottom: 1em;
  }

  .mobile-menu{
    position: sticky;
    bottom: -2px;
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 50px;
    z-index: 99999;
  }

  .trade-steps {
    justify-content: center;
  }

  .trade-steps button{
    margin-bottom: 1em;
    width: 100%;
    margin-top: 1em;
  }


  .App{
    display: block;
  }
  .collection-item{
    width: 100%;
  }
  .newsletter-container h1{
      width: 100%;
  }
  .newsletter-container input{
    width: 80%;
  }
  .newsletter-container h1{font-size: 1.6em;}
  .news-container h1{font-size: 1.5em;}
  
  .tutorial-video{display: none;}
  .tutorial-text{width: 65%;}
  .bar-container{margin-left: 2em;}
  .news-details p{max-width: 90%;}
  .project-item{width: 100%; margin-top: 2em;}
  .tutorial-button{width: 90%;}
  .news-details .news-content{max-width: 90%;}
  .wallet-info{width: 100%;}
  .trade-url{width: 100%; align-items:center;}
  .trade-offer-buttons button{margin-top: 1em;}
  .trade-offer-header h3{font-size: 0.8em; padding: 1em;}
  .given-nfts-container{width: 100%;}
  .affiliates-step{width: 100%; margin-bottom: 1em;}
  .news-collections input{width: 100px; margin-left: 0.5em;}
  .banner-title, .blink-caret{font-size: 2.5em;}
  .affiliates-item button{width: 90%;}
  .nft-trade-item{
    width: 170px;
  }
  .bot-step{
    width: 100%;
  }
  .bot-container h1{
    color: var(--text);
    font-size: 2.5em;
  }
  .receive-trade-offer{
    width: 100%;
  }

  .sent-trade-offer{
    width: 100%;
  }
}

.revoke-wrapper {
  text-align: center;
}

.revoke-wrapper .button {
  font-size: 1em;
  margin-left: 1em;
}

.text {
  font-size: 1.3em;
  font-family: 'Montserrat', sans-serif;
  color: var(--text);
}

.title {
  color: var(--news-title);
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}
